.historia-clinica{
 width: calc(100% - 60px);
 float: right;
}

.subtitulo  h2{
    text-align: center;
    color: var(--color-principal);
    padding: 20px;
    font-weight: 400;
    font-size: 24px;
}

.subtitulo  h3{
text-align: center;
color: var(--color-principal);
}

.crud-table{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.281);
    list-style: none;


}


i{
    float: right;
    top: -5px;
  
}