

.main-menu {
  margin-top: -0px;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  z-index: 101;
  backdrop-filter: blur(5px);
  /*background-color:   #3639A4;
	*/
  background-color: var(--color-principal);
  float: left;
  -moz-transition: width 0.05s linear;
  -ms-transition: width 0.05s linear;
  -o-transition: width 0.05s linear;
}



.dropdown-toggle {
  color: #ffffff;
}



.has-subnav i {
  color: #ffffff;
}

.logo i {
  color: #ffffff;
}

.logout i {
  color: #ffffff;
}

.fa-2x {
  font-size: 2em;
}
.fa,
.bi {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  padding: 10px;
 
}


.bi.logo-medcur {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: visible;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;

  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-align: justify;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: "Titillium Web", sans-serif;
}

.main-menu > ul.logout {
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.has-subnav:hover i {
  color: var(--principal-color_3);

}

/*  COLOR BACKGROUND SELECT */
.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {

background-color: rgb(80, 198, 219);
color: rgb(0, 0, 0);
}
.area {
  float: left;
  width: 100%;
  height: 100%;
}

.administrador-user {
  margin-bottom: 30px;
}

/*============== OPTIONS VENTANA ==============*/
#rol:hover {
  background-color: black;
  color: white;
}


span.nav-text {
	color: #ffffff;
  }
  span.nav-text:hover {
	color: #000000;
  }