#slider {
  background-color: var(--white);
  margin-left: 60px;
}

h5.slider-text {
  text-align: center;
  top: 40px;
  background-color: rgba(255, 255, 255, 0.205);
  backdrop-filter: blur(5px);
  color: var(--color-principal);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

p.slider-text {
  text-align: center;
  top: 40px;
  background-color: rgba(255, 255, 255, 0.205);
  backdrop-filter: blur(5px);
  color: var(--color-principal);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  
}
