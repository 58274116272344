.titulo-principal h2 {
  position: fixed;
  background-color: var(--color-principal);
  width: 100%;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 100;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  padding: 0px;
  top:0px;
  z-index: 100;
}

.menu-secundary nav {
  position:fixed;
  margin-top: 60px;
  width: 100%;
  height: 50px;
  background-color: var(--color-secundario);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgb(255, 255, 255);
}
.menu-secundary ul {
  display: flex;
}
.menu-secundary li {
  list-style: none;
  padding: 0px 50px;
  display: flex;
  justify-content: center;
}
.menu-secundary a {
  display: block;
  text-decoration: none;
  color: white;
  margin-right: 35px;
}

/* Box Registros */

.box-register {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-card {
  background-color: rgb(255, 255, 255);
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.575);
}

.box-card h3 {
  text-align: center;
}

.label-card {
  text-align: center;
  width: 100%;
  padding: 5px;
}

.input-card {
  text-align: center;
  border: 0px solid rgba(0, 0, 0, 0.342);
  background-color: aliceblue;
}
.btn-register {
  display: flex;
  justify-content: center;
  padding: 20px;
}

#rol {
  background-color: aliceblue;
  color: rgb(0, 0, 0);
  border: 1px solid rgba(102, 51, 153, 0);
}

button.boton-menu {
  background-color: rgb(142, 218, 19);
  padding: 10px;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

button.boton-menu:hover {
  background-color: var(--color-principal);
  box-shadow: 2px 2px 4px rgba(0, 2, 1, 0.4);
  color: #fff;
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}

#usuarios,
#clientes,
#marcas,
#colaboradores,
#proveedores {
  width: calc(100% - 0px);
  display: none;

}

#usuarios.active,
#clientes.active,
#marcas.active,
#colaboradores.active,
#proveedores.active {
  display: block;

}

#btn1, 
#btn2,
#btn3,
#btn4,
#btn5{
  background-color: rgb(255, 255, 255);
  color: #000;
}

#btn1.active,
#btn2.active,
#btn3.active,
#btn4.active,
#btn5.active{
background-color: var(--color-principal);
color: #fff;
}





#adm-users{
  margin-left: 60px;
  width: calc(100% - 70px);
}
#container2{
  position: absolute;
  width: 100%;
}
.contenido-din{
  margin-top: 90px;
}

.contenido-din.pacientes{
  margin-top: 100px;
}

.orden-internamiento{

margin-top: 100px;
margin-left: 60px;

}