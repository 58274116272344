.reportes {
  width: calc(100% - 60px);
  margin-left: 60px;
  
  
}

.info-graficas{
  width: 100%;
  background-color: rgba(69, 190, 32, 0.048);
  height: 150px;
}



.grafica{
  width: 100%;
  margin-top: 10px;

}
.grafica1{
  float: left;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grafica2{
  float: right;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.reportes h2 {
  text-align: center;
  font-weight: 400;
  color: white;
  height: 60px;
  background-color: var(--color-principal);
}

.reportes-seleccion {
  position: absolute;
  width: calc(100% - 60px);

  height: 100px;
  padding: 20px;
}

.tipo-usuario {
  float: left;
  width: 30%;
  /*     background-color: rgb(21, 141, 247); */
  margin: 1%;
}

.fecha-usuario {
  float: left;
  width: 30%;
  /*     background-color: rgb(19, 67, 109); */
  margin: 1% 3%;
}

.fecha-usuario-final {
  float: left;
  width: 30%;
  /*     background-color: rgb(20, 21, 22); */
  margin: 1%;
}

select.reportes {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  padding: 0;
  margin: 0 auto;
  margin: 2%;
}

.LineChart {
  width: 100%;
}

.informacion-graficas {
  display: flex;
  width: calc(100% - 60px);
  position: relative;
}

.tabla-reportes {
  padding: 2%;
  float: left;
  width: 50%;
}

.exportar-reportes {
  float: left;
  width: 50%;
  padding: 2%;
}



.titulo-reportes {
  background-color: rgba(69, 190, 32, 0.048);
}

textarea {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: black;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: black;
  background-color: rgb(255, 255, 255);
  font-size: 12px;
  text-align: center;
  width: 100%;
}



div.recharts-wrapper{

  width: 100px;
  
  

}

svg.recharts-suface{
  background-color: black;
  width: 100%;
  
  

}


.recharts-cartesian-grid-horizontal{
height: 100px;
}

