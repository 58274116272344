
.icon-detecciones{
    text-align: left;
    padding: 10px;
    color: white;
}

.row-detecciones{
    padding: 20px;
}
.tbody-detecciones{
    background-color: #3639A4 ;
    color: white;
    
}

.thead-detecciones{
    background-color: #3639A4 ;
    border-radius: 10px;
}
.button-detecciones{
    width: 90%;
    border-radius: 10px;
    background-color: var( --principal-color_4);
    padding: 10PX;
    color: white;

}
