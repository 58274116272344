#left{
  width: calc(50% - 20px);
  float: left;
  padding: 20px;
}

#right{
  width: calc(50% - 20px);
  float: left;
  padding: 20px;
}



#right h2 {
  color: var(--color-principal);
  font-weight: 600;
}

img.card-img-top {
  padding: 40px;
  border: 0px solid rgb(255, 255, 255);
  text-decoration: none;
}

.p-card-text {
  border: 2px solid rgb(250, 5, 5);
  border: none;
}

.comunicados {
  padding: 50px;
  text-decoration: underline;
  color: #3639a4;
}


.div-direccion {
  display: flex;
}

.input-direccion {
  margin-right: 10px;
}

.contenedor-datos-profesionales {
  margin-top: 100px !important;
}
.contenedor-documentacion-profesional {
  margin-top: 28px !important;
}

.buton-registrar {
  background-color: var(--principal-color_2);
  margin: 20px;
  width: 50%;
  border-radius: 10px;
  padding: 10px;
}

/*
    DEMO STYLE
*/

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  border: none;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
  background-color: #3639a4;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed rgb(0, 0, 0);
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}
#sidebarCollapse {
  background-color: rgba(86, 102, 172, 0);
  margin-left: 0px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  position: fixed;
  min-width: 250px;
  max-width: 250px;
  background: #3639a4;
  color: #fff;
  transition: all 0.3s;
  z-index: 3;

  height: 100%;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 12px 10px;
  text-align: center;
  font-size: 0.7em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6164bd;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #3639a4;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: rgb(137, 172, 212);
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #abb5e0;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    /*   margin-left: -80px !important; */
    margin-left: 0px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
