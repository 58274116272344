.relleno.completo.estudios select{
width: 100%;

}






.relleno.estudios p{
    color: #031a2b;
    text-align: left;
  
   font-size: 14px;
    
}



.relleno.estudios select{
   width: 100%;
    
}


.btn.btn-primary.enviar{
  
     justify-content: right;
     background-color: #3639a4;
     border-radius:10px;
     -webkit-border-radius:10px;
     -moz-border-radius:10px;
     -ms-border-radius:10px;
     -o-border-radius:10px;
     text-align: center;
     float: right;
     width: 33%;
   
}

.enviar{
    padding:  20px 10px;
}