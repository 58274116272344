#data-paciente{
    position:fixed;
    width: calc(30% - 140px);
    float: left;
    top: 10px;
    margin-left: 140px;
}

.buscador h4{
    color: var(--color-principal);
}

input.data{
    background-color: rgb(255, 255, 255);
    margin:5px;
    height: 20px;
}

p.data-nombre{
    color: var(--color-principal);
 
}