#clinicas {
  margin-left: 60px;
}


button.btn.btn-primary.registrar {
  background-color: var(--color-principal);
  float: right;
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2);
  translate: 0px 10px 0px;
}

button.btn.btn-primary.registrar:hover {
  background-color: var(--color-principal);
  float: right;
  box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.2);
  translate: 0px 0px 0px;
}

td.datos-personales{
    color: var(--color-principal);
    font-size: 12px;
    text-align: left + 60px;

}


#clinicas p{
    color: var(--color-principal);
}

@media screen and (max-width: 960px) {


    #clinicas {
        margin-left: 60px;
        width: calc(100% - 100px);
        padding: 0px;
      }

      

}
