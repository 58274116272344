
.icon{
    margin-right: 15px;

}
.input-icons{
    display: flex;
    align-items: flex-end;
    /* margin-left: 60px; */
    justify-content: space-evenly;
}

.title-login{
    text-align: center;
    color: white;
}


/* ******** MOVIL ******* */
@media (min-width:300px){
    .container-login{
        display: flex;
        flex-direction: column;

        position: absolute;
        right: 30px;
        position: absolute;
        top: 10%;
        left: 30px;
        bottom: 10%;
        border-radius: 20px;
        /* border: 5px solid var(--principal-color_2); */

    } 
    .login-left{
        background-color: #3639A4;
        border-radius: 15px 15px 0px 0px;
        padding: 40px;
        width: 100%;
        display: flex;
    }
    .img-logIn{
        width: 200px;
        margin-left: 0px;
        display: flex;
    }
    .personal-medico{
        display: none;
    }
    .login-right{
        background-color: #5668bf;
        border-radius: 0px 0px 15px 15px;
        padding: 20px;
        width: 100%;
    }
    .button-login {
        margin: 40px;
        width: 80%;
        border-radius: 10px;
        padding: 10px;
        /* position: absolute; */
        /* left: 60%; */
        background-color: var(--principal-color_2);
    }
    
    
}
@media (min-width:800px){
    .button-login {
        width: 50%;
        margin-left: 200PX;

    }

}
@media (min-width:1100px){


.container-page{
    width: 50%;
    

}


.login-right{
    background-color: #5668bf;
    border-radius: 0px 15px 15px 0px;
    padding: 40px;
    width: 80%;
}

.login-left{
    background-color: #3639A4;
    border-radius: 15px 0px 0px 15px;
    padding: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}
.button-login{
    margin: 50px;
    width: 20%;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    left: 60%;
    background-color: var(--principal-color_2);
   

}


.container-login{
    position: absolute;
    right: 100px;
    top: 20%;
    left: 100px;
    bottom: 10%;
    border-radius: 20px;
    /* border: 5px solid var(--principal-color_2); */
    display: flex;
    flex-direction: row;


}
.img-logIn{
    width: 65%;
    margin-left: 90px;
    
}
.personal-medico{
    display: block;
}
}
