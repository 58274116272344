.comunicados{
    position: relative;
    margin-left: 60px;
    margin-top: 60px;
}
.adm-comunicados{
    margin-top: 60px;
}


#programas-menu{
    width: calc(100% -60px);
    margin-left: 60px;
}

.personal.programas{
    width: calc(100% - 60px);
    margin-left: 60px;
}


.cargar-imagen{
    float: left;
    width:calc(40% - 40px);
   margin-top: 20px;
   margin: 20px;
}

.cargar-imagen p{
  color: var(  --color-principal);
}

.preview{
    float: left;
    width:calc(60% - 40px );
    margin-top: 20px;
    margin: 20px;
    display: flex;
justify-content: center;
align-items: center;
}

.preview img{
width: 70%;

}

#programas{
    width: 50%;
  }
  