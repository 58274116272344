#consultas-menu{
    position: relative;
    width: calc(100% - 60px);
    margin-left: 60px;
  
    
}


#content-consultas{
    position: relative;
    top: 20px;
    padding: 0px;
}

div.contenido-izquierdo{
width: 100%;
background-color: rgba(255, 228, 196, 0);

}