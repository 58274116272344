.container-planificacionFam{
   width: calc(100% - 60px);
    margin-left: 60px;

}
.container-izq-der{
    display: flex;
    align-items: flex-end;
}


.prueba.derecha{
margin-top: 30px;
}