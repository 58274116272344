#urgencias-menu{
    width: calc(100% - 60px);
    margin-left: 60px;
}


.data-consul{
 margin-left: -60px;
}

.adm-urgencias{
    margin-left: 60px;
    margin-top: 40px;
}
.personal.urgencias{
    width: calc(100% - 60px);
    margin-left: 60px;
    margin-top: 20px;
}

th,td{
text-align: center;
}


.nota-medica{
    margin-left: 60px;
    width: 100% - 60px;
}

.relleno{

 font-size: 10px;
}

.relleno span{
    padding: 0px;
    text-align: center;
}

.relleno input{
    font-size: 10px;
    /* height: 30px; */


}
.select-file{
    padding: 10px;
}

.diag{
    width: 100%;
}


.relleno.diagnostico p{
    color: black;
}



textarea{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 100%;
}
