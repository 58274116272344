#right span{
    width: 100%;
}

#right p{
    width: 50%;
    float: left;
    font-size: 12px;
    color: black;
    text-align: justify;
}


#right input{
    float: right;
    width: 50%;
}



