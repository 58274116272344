div#comunicados{
    position: relative;
    background-color: var(--white);
    padding: 20px;
}

#comunicados h2{
    color: var(--color-principal);
    font-weight: 400;
    text-align: center;
    padding: 20px;
}

.card-group.comunicados{
padding: 0px;
box-shadow: 2px 8px 4px rgba(0, 0, 0, 0.486);
}

div.card-title.h5{
color: var(--color-principal);
text-align:center ;
text-decoration: none;
   
}

div.card-subtitle.h6{
    color: var(--color-principal);
    text-align: right;
    padding: 5px;
    text-decoration: none;
   
}

p.card-text{
    text-decoration: underline rgb(255, 255, 255);
    color: #000000;
    text-align: justify;
    font-weight: 300;
    font-size: 12px;
  }
  
  a.links{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    color: var(--color-principal);
    float: right;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.253);
    font-size: 12px;
    font-weight: 600;
    translate: 0px 5px 0px;
}


a.links:hover{
    background-color:var(--principal-color_2); 
    translate: 0px 0px 0px;
}