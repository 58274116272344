
.datos-generales {
  padding: 0px;
}



.section {
  background-color: #ffffff;
}

#left,
#right {
  width: 49%;
}

.historia-familiar {
  width: 100%;
  padding: 20px;
}

.antecedentes {
  position: relative;
  height: 100%;
}

input.input-right {
  margin-left: 50%;
}

button.btn.buton-registrar:hover {
  background-color: rgb(15, 89, 99);
}

.buscador {
  width: 90%;
}

.buscador h4 {
  text-align: center;
  color: #031a2b;
  padding: 20px 0px;
}

.checar {
  margin-left: 50px;
}

tr.prueban {
  font-size: 10px;

}


.prueba {
  padding: 0px 0px;
}

.relleno.salud {
  width: 50%;
}
.relleno.salud select {
  width: 100%;
}

.contenido-izquierdo {
  position: absolute;
  left: 0px;
  width: calc(100%);
  top: 120px;
  background-color: rgb(255, 255, 255);
}

.izquierdo {
  width: 50%;
  float: left;
}

.relleno.relleno.salud {
  width: 100%;
}
.derecho {
  width: 50%;
  float: right;
}

.contenido-derecho {
  position: absolute;
  right: 0px;
  width: calc(100%);
  top: 120px;
}

.prueba {
  width: 30%;
  float: left;
}

.prueba.derecha {
  width: calc(70%);
  float: right;
}

.usuarios select {
  width: 100%;
}



button.sn-boton {
  font-size: 20px;
  text-align: center;
}

/* VARIABLES CSS  */
td {
  letter-spacing: 2px;
}

th.color-table {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 400;
  border-bottom: 1px solid #00000023;
  font-size: 14px;
  text-align: center;
  padding: 10px;
}

td.border-td {
  color: rgb(0, 0, 0);
  font-size: 12px;
  padding: 0px;
  border-bottom: 1px solid #00000023;
  text-align: center;
  padding: 10px 0px 10px;
}

.titulo p {
  text-align: left;
  color: #000000bb;
  padding: 20px 10px;
}

table.administracion {
  width: 30%;
  margin: 20px 20px;
  padding: 20px;
}

td.false {
  background-color: var(--principal-color_4);
  border-bottom: none;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-right: 2px solid rgb(18, 66, 129);
  border-left: 2px solid rgb(18, 66, 129);
}

td.true {
  background-color: var(--principal-color_2);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-bottom: none;
  text-align: center;
  text-decoration: none;
  border-right: 2px solid rgb(18, 66, 129);
  border-left: 2px solid rgb(18, 66, 129);
}

#administrar {
  width: calc(100% - 60px);
}

@media screen and (max-width: 960px) {
  .content-clinica {
    width: calc(100% - 60px);
  }

  div#contenido {
    background-color: #ffffff;
    width: calc(100% - 60px);
  }

  div#contenido#slider {
    background-color: #ffffff;
    width: calc(100%);
  }

  div.recharts-wrapper {
    background-color: #ffffff;
  }

  .personal {
    margin-top: 100px;
    width: 100%;
    left: 0px;
  }
}

td.pivoted {
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
  padding: 10px 20px;
}

tr.titulo-tabla {
  background-color: rgb(255, 255, 255);
  color: #3639a4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}










