.main-menu.sub-menu {
  position: fixed;
  backdrop-filter: blur(2px);
  background-color: #363aa480;
  float: left;
  margin-left: 60px;
   margin-top: 60px;
  height: calc(100%);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  z-index: 10;
}


/* Tamaño Tablas Menus */
.table.size {
  width: 40%;
}

button {
  border: none;
}
button:visited {
  text-decoration: underline;
  color: #000;
}

button:focus {
  text-decoration: underline solid 2px rgba(63, 26, 133, 0.616);
}

.sn-boton {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.ocultar {
  display: none;
}

#sidebarCollaps {
  float: left;
}
.personal {
  position: absolute;
  left: 0px;
  width: calc(100%);
  top: 120px;
}

/*================================================= 
                    Iconos 
  =================================================*/
svg.iconify.iconify--emojione-monotone,
svg.iconify.iconify--fa6-solid,
svg.iconify.iconify--ri {
  color: gray;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

svg.iconify.iconify--emojione-monotone:hover,
svg.iconify.iconify--fa6-solid:hover,
svg.iconify.iconify--ri:hover {
  color: rgb(42, 165, 79);

  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.mediciones.active {
  display: none;
}
.cobertura.active {
  display: none;
}

.saludr.active {
  display: none;
}

.otros.active {
  display: none;
}

.saludn.active {
  display: none;
}

.promocion.active {
  display: none;
}

.horarios.active {
  display: none;
}


.estudios{
margin-left: 30px;
}