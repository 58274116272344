#content-clinica{
    margin: 6px  30px;
}


table.administrar  td{
    color: #0a0a0a;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0px;
}


.box{
    width: 100%;
}

img.logo{
    display: flex;
    width: 30px;
   margin-left: 15px;
   margin-top: 10px;
   padding: 0px 0px 10px;
   justify-content: center;
}




.has-subnav.logo{
    background-color: #ffffff;
  
}



.relleno.div{
    width: 33%;
    padding: 0px;
}

.relleno.div p{
    width: 300%;
}


