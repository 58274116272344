/* ---------------------------------------------------
    CONTENT STYLE PRINCIPAL
----------------------------------------------------- */
* {
  margin: 0;
  padding: 0;

}

:root {
  --color-principal: #3639a4;
  --principal-color_1: #8aa2d4;
  --principal-color_2: #d1e9ff;
  --principal-color_3: #414370;
  --principal-color_4: #a2d3ff;
  --principal-color: #313632;
  --white: #ffffff;

}

select {
  color: #000000;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  background-color: #ffffff;
  text-align: center;
  width: 100px;
  height: 25px;
}

input {
  color: #000000;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  text-align: center;
  width: 100px;
  height: 25px;
  font-size: 14px;
}

::placeholder {
  text-align: center;
}

.re-Captcha {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-left: 60px;
}


/* ================================== Transparencia fondo menus ==================================*/

@media screen and (max-width: 960px) {
  .busqueda-proyectos {
    z-index: 1;
    position: fixed;
    top: 0px;
    width: calc(100% - 60px);
    left: 60px;
    background-color: var(--administrador-bg-color);
  }

  .btn {
    font-size: 16px;
    padding: 1px 10px;
    letter-spacing: 1px;
    border: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
}

.scene {
  top: 130px;
}

h2 {
  font-weight: 300;
  font-size: 30px;
}
