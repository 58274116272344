.relleno.med p{
    color: #031a2b;
    text-align: left;
    font-size: 14px;
}


.relleno{

    padding: 0px 10px;
    width: 33%;

  }
  
  .relleno p{
    color: #ffffff;
  }
  
  .relleno.cob p, .relleno.salud p{
    color: #031a2b;
    text-align: left;
   font-size: 14px;
  }
  
  .relleno span{
    position: relative;
    background-color: #3639a4;
    width: 100%;
    height: 20px;
    border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -ms-border-radius: 10px 10px 0px 0px;
    -o-border-radius: 10px 10px 0px 0px;
}

  .relleno.box{
    background-color: #3639a4;
    height: 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

  .relleno input{
    display: flex;
    background-color: #ffffff;
    height: 20px;
    text-align: center;
  
}

#box{
  margin-top: -15px;
  border-radius: 0px 0px 10px 10px;
}
  
  
  .relleno.completo{
    width: 66%;
  }
  
  .box{
    background-color: #1f1a6e;
    width: 100%;
  }
  
  
  .dividir{
   width: 33%;
   height: 300px;
  /* background-color: #031a2b; */
  float: left;
  color: #031a2b;
 
  }
  .dividir select{
    width: 300%;
  }

  .caja-info{
    width: 66%;
    float: right;
    height: 300px;
  /*   background-color: #031a2b; */
  
  }
  
  
  .dividir p{
    color: #031a2b;
  }
  
  .caja-info p{
    color: #031a2b;
  }

  
  
  
  
  
  select.relleno.div {
    width: 100%;
    font-size: 12px;
  }
  
  .relleno.caja{
    width: 100%;
  }
  
  


  p.titulos-tablas{
    color: #3639a4;
    text-align: center;
   padding: 0px  20px 0px;
   font-size: 16px;
   width: 220px;
   text-decoration: none;

  }

  
  p.titulos-tablas.grandes{
   width: 320px;
  }

  .data-consulta{
    width: calc(100% - 60px);
    margin-left: 60px;

  }

  th.titulo {
    color: var(--color-principal);
    padding: 20px;
  }

  .titulo h2 {
    color: var(--color-principal);
    font-weight: 600;
    font-size: 28px;
    font-family: sans-serif;
    
  }


#right p{
  padding: 0px 20px;
}

  .titulo p {
    text-align: left;
  }
  

  .titulo h2 {
    text-align: center;
    padding: 20px;
  }
  
  
.subtitulo h3 {
  text-align: center;
  width: 100%;
}

.subtitulo h2 {
  text-align: center;
}

.titulo-columna{
  background-color: #414370;
  color: white;
}
.titulo.logo{
  color: rgb(0, 0, 0);
  text-align: left;
  font-weight: 900;
  margin: 10px 20px;
  }

  